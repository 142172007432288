










































import useColor from "@/use/color";
import useEmissionStandard from "@/use/emissionStandard";
import useNumber from "@/use/number";
import { computed, defineComponent, reactive } from "@vue/composition-api";
import { watchDebounced } from "@vueuse/shared";
import { AxiosInstance } from "axios";

export default defineComponent({
  props: {
    userId: {
      type: String,
      required: false,
      default: null,
    },
    available: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: Object,
      required: false,
      default: null,
    },
    vehicles: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup(props, { root, emit }) {
    const { emissionStandards } = useEmissionStandard();
    const { formatNumber } = useNumber();
    const { isDark } = useColor();

    const state = reactive({
      search: "",
      loading: false,
      items: [],
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 5,
      },
      headers: [
        { value: "select", text: "", width: 1, sortable: false },
        { value: "name", text: "Marka/model", sortable: false },
        {
          value: "registrationNumber",
          text: "Numer rejestracyjny",
          sortable: false,
        },
        { value: "vinNumber", text: "Numer VIN", sortable: false },
        { value: "mileage", text: "Przebieg", sortable: false },
      ],
    });

    const model = computed({
      get: () => props.value,
      set: (value) => emit("input", value),
    });

    const fetch = () => {
      state.loading = true;

      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      axiosInstance
        .get(
          props.userId
            ? `inventory/user/${props.userId}/vehicle`
            : "inventory/vehicle",
          {
            params: {
              type: props.available ? "available" : "occupied",
              search: state.search || undefined,
              page: state.options?.page || undefined,
              itemsPerPage: state.options?.itemsPerPage || undefined,
            },
          }
        )
        .then(({ data: { vehicles, total } }) => {
          state.items = vehicles;
          state.total = total;
        })
        .catch(() => {
          state.items = [];
          state.total = 0;
        })
        .finally(() => (state.loading = false));
    };

    watchDebounced(
      [
        () => props.userId,
        () => props.available,
        () => state.search,
        () => state.options,
      ],
      fetch,
      {
        deep: true,
        immediate: true,
        debounce: 500,
        maxWait: 5000,
      }
    );

    return { isDark, emissionStandards, formatNumber, state, model };
  },
});
