var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-inventory-vehicles-select"},[(_vm.model)?_c('v-card',{staticClass:"mb-6",attrs:{"outlined":""}},[_c('v-list-item',{attrs:{"dense":""}},[_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","to":{ name: 'panel.vehicle.view', params: { id: _vm.model.id } }}},[_c('v-icon',[_vm._v("mdi-car")])],1)],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('span',[_vm._v(_vm._s(_vm.model.details && _vm.model.details.vehicleBrand)+" "+_vm._s(_vm.model.details && _vm.model.details.vehicleModel))]),_vm._l(([_vm.emissionStandards.find(function (ref) {
	var value = ref.value;

	return value === _vm.model.emissionStandard;
})]),function(standard){return (_vm.model.emissionStandard)?_c('v-chip',{key:standard.value,staticClass:"ml-2",attrs:{"x-small":"","label":"","dark":_vm.isDark(standard.color),"color":standard.color}},[_vm._v(_vm._s(standard.text))]):_vm._e()})],2),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.model.mileages && _vm.model.mileages[0] && _vm.formatNumber.kilometer(_vm.model.mileages[0].mileage || 0)))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.model.details && _vm.model.details.registrationNumber))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.model.details && _vm.model.details.vinNumber))])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.model = null}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1):_vm._e(),_c('v-card',{staticClass:"mb-2",attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('span',[_vm._v(_vm._s(_vm.available ? 'Dostępne pojazdy' : 'Pojazdy użytkownika'))])]),_c('v-divider'),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Szukaj","outlined":"","dense":"","hide-details":"","clearable":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.state.search),callback:function ($$v) {_vm.$set(_vm.state, "search", $$v)},expression:"state.search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.state.headers,"items":_vm.state.items,"options":_vm.state.options,"server-items-length":_vm.state.total,"footer-props":{ itemsPerPageOptions: [5, 10] }},on:{"update:options":function($event){return _vm.$set(_vm.state, "options", $event)},"click:row":function (item) { return _vm.model = item; }},scopedSlots:_vm._u([{key:"item.select",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.model && _vm.model.id === item.id ? 'primary' : null}},[_vm._v(_vm._s(_vm.model && _vm.model.id === item.id ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank'))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.details.vehicleBrand)+" "+_vm._s(item.details.vehicleModel))]}},{key:"item.registrationNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.details.registrationNumber))]}},{key:"item.vinNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.details.vinNumber))]}},{key:"item.mileage",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.mileages && item.mileages[0] && _vm.formatNumber.kilometer(item.mileages[0].mileage || 0)))]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }